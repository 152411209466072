import { Box, Typography } from '@mui/material';
import { ReactNode } from 'react';

function PageLayout({
  fullWidth,
  fullHeight,
  gap,
  color,
  title,
  children,
}: {
  children: ReactNode;
  color?: 'secondary';
  fullHeight?: boolean;
  fullWidth?: boolean;
  gap?: number;
  title?: ReactNode;
}): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={fullHeight ? 1 : undefined}
      paddingX={fullWidth ? 0 : 3}
      paddingY={5}
      sx={({ palette }) => ({ a: { color: palette.secondary.main } })}
    >
      <Box
        alignItems="center"
        display="flex"
        flexDirection="column"
        flexGrow={fullHeight ? 1 : undefined}
        gap={gap || 3}
        marginX="auto"
        maxWidth="100%"
        width={fullWidth ? '100%' : 1200}
      >
        {title && (
          <Typography
            align="center"
            color={color || 'primary'}
            component="h1"
            fontWeight={600}
            variant="h4"
          >
            {title}
          </Typography>
        )}
        {children}
      </Box>
    </Box>
  );
}

export default PageLayout;
