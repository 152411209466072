import {
  AppleIcon,
  FacebookIcon,
  GeogroupService,
  GoogleIcon,
  User,
  UserService,
  useSignIn,
} from '@geovelo-frontends/commons';
import { Box, Button, ButtonProps } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { AppContext } from '../../app/context';

function SSO({ signUp, setLoading }: { setLoading: (loading: boolean) => void; signUp?: boolean }) {
  const {
    actions: { setCurrentUser, setGeogroups, setPartners },
  } = useContext(AppContext);
  const {
    t,
    i18n: { language: currentLanguage },
  } = useTranslation();
  const snackbar = useSnackbar();
  const { facebookSignIn, googleSignIn, appleSignIn } = useSignIn({
    setLoading,
  });

  async function handleFacebookSignIn() {
    try {
      const user = await facebookSignIn(currentLanguage);

      setCurrentUser(user);

      await getUserData(user);
    } catch (err) {
      snackbar?.enqueueSnackbar(t('commons.sign_in_dialog.server_error'));
    }
  }

  async function handleGoogleSignIn() {
    try {
      const user = await googleSignIn(currentLanguage);

      setCurrentUser(user);

      await getUserData(user);
    } catch (err) {
      snackbar?.enqueueSnackbar(t('commons.sign_in_dialog.server_error'));
    }
  }

  async function handleAppleSignIn() {
    try {
      const user = await appleSignIn(currentLanguage);

      setCurrentUser(user);

      await getUserData(user);
    } catch (err) {
      snackbar?.enqueueSnackbar(t('commons.sign_in_dialog.server_error'));
    }
  }

  async function getUserData(user: User) {
    try {
      const geogroups = await GeogroupService.getUserGeogroups();

      const partners = await UserService.getPartners({
        isAdmin: user?.isGeovelo,
        isSupport: user?.isSupport,
      });
      setGeogroups(
        geogroups.filter(
          ({ group: { id, type }, isAdmin }) =>
            type !== 'city' || isAdmin || partners?.find(({ geoGroupId }) => geoGroupId === id),
        ) || [],
      );
      setPartners(partners);
    } catch (err) {
      snackbar?.enqueueSnackbar(t('commons.sign_in_dialog.server_error'));
    }
  }

  return (
    <Box
      alignItems="center"
      alignSelf="stretch"
      display="flex"
      flexDirection="column"
      gap={2}
      justifyContent="stretch"
    >
      <StyledButton onClick={handleGoogleSignIn} size="large" variant="outlined">
        <GoogleIcon />
        {t(
          signUp
            ? 'commons.sign_in_dialog.sign_up_with.google'
            : 'commons.sign_in_dialog.sign_in_with.google',
        )}
      </StyledButton>
      {!signUp && (
        <StyledButton onClick={handleFacebookSignIn} size="large" variant="outlined">
          <FacebookIcon sx={{ color: '#485a96' }} />
          {t(
            signUp
              ? 'commons.sign_in_dialog.sign_up_with.facebook'
              : 'commons.sign_in_dialog.sign_in_with.facebook',
          )}
        </StyledButton>
      )}
      <StyledButton onClick={handleAppleSignIn} size="large" variant="outlined">
        <AppleIcon />
        {t(
          signUp
            ? 'commons.sign_in_dialog.sign_up_with.apple'
            : 'commons.sign_in_dialog.sign_in_with.apple',
        )}
      </StyledButton>
    </Box>
  );
}

function StyledButton({ ...props }: Omit<ButtonProps, 'component'>): JSX.Element {
  return (
    <Button
      {...props}
      color="inherit"
      sx={{
        ...props.sx,
        border: '1px solid #cfcfcf',
        gap: '8px',
        height: '60px',
        width: '100%',
        textTransform: 'initial',
        fontSize: '1.125rem',
      }}
    />
  );
}

export default SSO;
