import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  eventId: number;
  mdvEventId: number;
  prevEventId: number;
  registrationsOpen: boolean;
  preregistrationLink: string;
} = {
  ...defaultEnvironment,
  source: process.env.REACT_APP_CONFIG === 'mdv' ? 'MOIS_DU_VELO' : 'MAI_A_VELO',
  apiKey:
    process.env.REACT_APP_CONFIG === 'mdv'
      ? '58df85c7-845d-41cc-a248-f444d27fa560'
      : 'd238b39e-3d11-4762-b769-e2f22eb0ba2a',
  prevEventId: 6,
  eventId: 29,
  mdvEventId: 7,
  registrationsOpen: false,
  preregistrationLink:
    'https://framaforms.org/pre-inscription-challenges-mai-a-velo-2025-1731590135',
};

if (!process.env.REACT_APP_ENV || process.env.REACT_APP_ENV === 'development') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
    prevEventId: 39,
    eventId: 82,
    mdvEventId: 40,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
    prevEventId: 39,
    eventId: 82,
    mdvEventId: 40,
  };
}

export const environment = _environment;
