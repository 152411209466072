import {
  AuthService,
  CyclabilityZone,
  EventService,
  Geogroup,
  HttpService,
  Place,
  TFile,
  TGeogroupType,
} from '@geovelo-frontends/commons';
import { Schedule } from '@mui/icons-material';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { AppContext } from '../../app/context';
import { environment } from '../../environment';
import RegisterLayout from '../../layouts/register';
import { entities } from '../../models/entities';

import Address from './address';
import CommunityChoice from './community-choice';
import Zone from './cyclability-zone';
import EntitySize from './entity-size';
import EntityType from './entity-type';
import Login from './login';
import Logo from './logo';
import Name from './name';
import Redirect from './redirect';
import SameLocation from './same-location';

const now = moment();

type TStep =
  | 'login'
  | 'geogroups'
  | 'entityType'
  | 'redirect'
  | 'cyclabilityZone'
  | 'entitySize'
  | 'logo'
  | 'personalData'
  | 'name'
  | 'address'
  | 'sameLocation';

function Register(): JSX.Element {
  const [step, setStep] = useState<TStep>('login');
  const [prevSteps, setPrevSteps] = useState<TStep[]>([]);
  const [entityType, setEntityType] = useState<TGeogroupType | 'individual'>();
  const [cyclabilityZone, setCyclabilityZone] = useState<CyclabilityZone>();
  const [invitationLink, setInvitationLink] = useState<string>();
  const [hasContract, setHasContract] = useState<boolean>();
  const [sizeKey, setSizeKey] = useState<string>();
  const [icon, setIcon] = useState<TFile>();
  const [name, setName] = useState('');
  const [address, setAddress] = useState<Place | null>(null);
  const [sameLocation, setSameLocation] = useState<boolean>();
  const [loading, setLoading] = useState(false);
  const [geogroup, setGeogroup] = useState<Geogroup>();
  const [selectedGeogroup, selectGeogroup] = useState<Geogroup | null>(null);
  const {
    actions: { setCurrentUser },
    user: { current: currentUser, geogroups },
    event: { current: currentEvent, config },
  } = useContext(AppContext);
  const navigate = useNavigate();
  const snackbar = useSnackbar();

  function handlePrevClick() {
    setStep(prevSteps[prevSteps.length - 1]);
    setPrevSteps([...prevSteps].slice(0, -1));
  }

  useEffect(() => {
    if (currentUser) {
      if (geogroups && geogroups.length > 0) setStep('geogroups');
      else setStep('entityType');
    } else setStep('login');
  }, [currentUser]);

  async function handleSubmit() {
    const entity = entities.find(({ key }) => key === entityType);
    if (!entity || !currentUser) return;

    const { key: type, sizes } = entity;
    const size = sizes.find(({ key }) => key === sizeKey);
    if (type === 'area' || !size) return;
    if (type === 'city' && ((!cyclabilityZone && config !== 'mdv') || (!name && config === 'mdv')))
      return;

    setLoading(true);

    try {
      const geogroup = await EventService.addGeogroup(
        config === 'mdv' ? environment.mdvEventId : environment.eventId,
        {
          type: type === 'city' && config === 'mdv' ? 'private' : type,
          name: type === 'city' ? cyclabilityZone?.name || name || '' : name,
          min: size.min,
          max: size.max,
          address,
          sameLocation,
          cyclabilityZone,
          icon: icon instanceof File ? icon : null,
          email: currentUser.email,
        },
      );

      setGeogroup(geogroup);
      navigate('/merci', { state: { entityType } });
    } catch (err) {
      console.error(err);
    }

    setLoading(false);
  }

  async function signOut() {
    try {
      await AuthService.signOut();
      setCurrentUser(null);
    } catch {
      snackbar?.enqueueSnackbar("Vous n'avez pas pu être déconnecté");
    }
  }

  function toCommunityCreation() {
    setPrevSteps([...prevSteps, 'geogroups']);
    setStep('entityType');
  }

  if (!currentEvent) {
    return (
      <RegisterLayout>
        <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
          <CircularProgress />
          <Typography>Récupération de l'évènement</Typography>
        </Box>
      </RegisterLayout>
    );
  }

  if (!environment.registrationsOpen) {
    return (
      <RegisterLayout>
        <Box alignItems="center" display="flex" flexDirection="column" gap={4} paddingY={5}>
          <Schedule color="action" fontSize="large" />
          <Typography>
            Les inscriptions pour {currentEvent.title} ouvriront bientôt, pré-inscrivez vous
            ci-dessous !
          </Typography>
          <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
            <Button
              disableElevation
              component="a"
              href={environment.preregistrationLink}
              rel="noreferrer"
              sx={{
                '&&': { color: '#fff' },
                borderRadius: 100,
                textTransform: 'initial',
              }}
              target="_blank"
              variant="contained"
            >
              Préinscription {currentEvent.title}
            </Button>
          </Box>
        </Box>
      </RegisterLayout>
    );
  }

  if (currentEvent.endDate.isBefore(now.clone().add(5, 'days'))) {
    const year = currentEvent.startDate.format('YYYY');

    return (
      <RegisterLayout>
        <Box alignItems="center" display="flex" flexDirection="column" gap={4} paddingY={5}>
          <Schedule color="action" fontSize="large" />
          <Typography>Les inscriptions pour {currentEvent.title} sont terminées !</Typography>
          <Box alignItems="center" display="flex" flexDirection="column" gap={1}>
            <Button
              disableElevation
              component={Link}
              sx={{
                '&&': { color: '#0c3f61' },
                borderRadius: 100,
                textTransform: 'initial',
              }}
              to="/classements"
              variant="outlined"
            >
              Voir les résultats {year}
            </Button>
          </Box>
        </Box>
      </RegisterLayout>
    );
  }

  if (loading) {
    return (
      <RegisterLayout>
        <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
          <CircularProgress />
          <Typography>Inscription de votre communauté</Typography>
        </Box>
      </RegisterLayout>
    );
  }

  if (geogroup) return <></>;

  switch (step) {
    case 'login':
      return <Login />;
    case 'geogroups':
      return (
        <CommunityChoice
          createCommunity={toCommunityCreation}
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'geogroups']);
            setStep('redirect');
          }}
          onPrevClick={() => {
            selectGeogroup(null);
            signOut();
          }}
          selectedGeogroup={selectedGeogroup}
          selectGeogroup={selectGeogroup}
        />
      );
    case 'entityType':
      return (
        <EntityType
          entityType={entityType}
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'entityType']);
            if (entityType === 'individual')
              window.open(
                `${environment.frontendUrl}/fr/communities/invites/CZFPBIL/${
                  currentUser
                    ? `?auth-token=${HttpService.authorizationToken?.substring(
                        HttpService.authorizationToken.indexOf(' ') + 1,
                      )}&user-id=${currentUser.id}`
                    : ''
                }`,
              );
            else if (entityType === 'city' && config !== 'mdv') setStep('cyclabilityZone');
            else if (entityType === 'company') setStep('redirect');
            else setStep('name');
          }}
          onPrevClick={() => {
            if (geogroups && geogroups.length > 0) handlePrevClick();
            else signOut();
          }}
          prevButtonLabel={geogroups && geogroups.length > 0 ? undefined : 'Changer de compte'}
          setEntityType={setEntityType}
        />
      );
    case 'redirect':
      return (
        <Redirect
          entityType={entityType}
          onPrevClick={() => {
            setCyclabilityZone(undefined);
            selectGeogroup(null);
            handlePrevClick();
          }}
          selectedGeogroup={selectedGeogroup}
        />
      );
    case 'cyclabilityZone':
      return (
        <Zone
          cyclabilityZone={cyclabilityZone}
          hasContract={hasContract}
          invitationLink={invitationLink}
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'cyclabilityZone']);
            if (invitationLink || hasContract) setStep('redirect');
            else setStep('entitySize');
          }}
          onPrevClick={handlePrevClick}
          setCyclabilityZone={setCyclabilityZone}
          setHasContract={setHasContract}
          setInvitationLink={setInvitationLink}
        />
      );
    case 'entitySize':
      const entity = entityType && entities.find(({ key }) => key === entityType);
      return (
        (entity && (
          <EntitySize
            entity={entity}
            onNextClick={() => {
              setPrevSteps([...prevSteps, 'entitySize']);
              if (entityType === 'city' && config !== 'mdv') setStep('logo');
              else setStep('address');
            }}
            onPrevClick={() => {
              setCyclabilityZone(undefined);
              handlePrevClick();
            }}
            setSizeKey={setSizeKey}
            sizeKey={sizeKey}
          />
        )) || <></>
      );
    case 'logo':
      return (
        <Logo
          entityType={entityType}
          icon={icon}
          onNextClick={handleSubmit}
          onPrevClick={handlePrevClick}
          setIcon={setIcon}
        />
      );
    case 'name':
      return (
        <Name
          entityType={entityType}
          name={name}
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'name']);
            setStep('entitySize');
          }}
          onPrevClick={handlePrevClick}
          setName={setName}
        />
      );
    case 'address':
      return (
        <Address
          address={address}
          entityType={entityType}
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'address']);
            if (entityType === 'company') setStep('sameLocation');
            else setStep('logo');
          }}
          onPrevClick={handlePrevClick}
          setAddress={setAddress}
        />
      );
    case 'sameLocation':
      return (
        <SameLocation
          onNextClick={() => {
            setPrevSteps([...prevSteps, 'sameLocation']);
            setStep('logo');
          }}
          onPrevClick={handlePrevClick}
          sameLocation={sameLocation}
          setSameLocation={setSameLocation}
        />
      );
    default:
      return <></>;
  }
}

export default Register;
