import { Geogroup, HttpService, TGeogroupType } from '@geovelo-frontends/commons';
import { Box, Button, CircularProgress, Typography } from '@mui/material';
import { useContext } from 'react';

import { AppContext } from '../../app/context';
import registerGeovelo from '../../assets/img/register-geovelo.png';
import { environment } from '../../environment';
import RegisterLayout, { TRegisterLayoutProps } from '../../layouts/register';

function Redirect({
  entityType,
  selectedGeogroup,
  ...props
}: TRegisterLayoutProps & {
  entityType: TGeogroupType | 'individual' | undefined;
  selectedGeogroup: Geogroup | null;
}): JSX.Element {
  const {
    event: { current: currentEvent, config },
    user: { current: currentUser, partners },
  } = useContext(AppContext);

  if (!currentEvent) {
    return (
      <RegisterLayout>
        <Box alignItems="center" display="flex" flexDirection="column" gap={2} paddingY={5}>
          <CircularProgress />
          <Typography>Récupération de l'évènement</Typography>
        </Box>
      </RegisterLayout>
    );
  }

  const year = currentEvent.startDate.format('YYYY');

  return (
    <RegisterLayout
      {...props}
      title={
        !selectedGeogroup && entityType === 'company'
          ? `Pour finaliser votre inscription ${
              config === 'mdv' ? 'au Mois du Vélo' : 'à Mai à Vélo'
            }, vous allez être redirigé.e vers la plateforme Geovelo Entreprise pour enregistrer votre organisation.`
          : `Inscrivez-vous ${
              config === 'mdv' ? 'au Mois du vélo' : 'à Mai à Vélo'
            } ${year} directement depuis l’espace Administrateur de votre communauté Geovelo !`
      }
    >
      {selectedGeogroup ? (
        <>
          {selectedGeogroup.type === 'company' ? (
            selectedGeogroup.partner ? (
              <>
                <Button
                  disableElevation
                  color="primary"
                  component="a"
                  href={`${environment.enterpriseUrl}${
                    currentUser
                      ? `?auth-token=${HttpService.authorizationToken?.substring(
                          HttpService.authorizationToken.indexOf(' ') + 1,
                        )}&user-id=${currentUser.id}&geogroup-id=${
                          selectedGeogroup.id
                        }&utm_source=${config || 'mav'}`
                      : ''
                  }`}
                  rel="noreferrer"
                  sx={{
                    borderRadius: 100,
                    '&&': { color: '#fff' },
                    textTransform: 'initial',
                  }}
                  target="_blank"
                  variant="contained"
                >
                  Consulter {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Geovelo
                  Entreprise
                </Button>
                <Typography>
                  En tant qu'entreprise, votre communauté est d'ores et déjà inscrite{' '}
                  {config === 'mdv' ? 'au Mois du Vélo' : 'à Mai à Vélo'} {year}. Retrouvez les
                  informations de votre challenge sur Geovelo Entreprise !
                </Typography>
                <Typography>
                  Si vous ne parvenez pas à rejoindre l’administration de votre communauté, veuillez
                  nous contacter à <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
                </Typography>
              </>
            ) : (
              <>
                <Button
                  disableElevation
                  color="primary"
                  component="a"
                  href={`${environment.enterpriseUrl}subscription/connect${
                    currentUser
                      ? `?auth-token=${HttpService.authorizationToken?.substring(
                          HttpService.authorizationToken.indexOf(' ') + 1,
                        )}&user-id=${currentUser.id}&geogroup-id=${
                          selectedGeogroup.id
                        }&utm_source=${config || 'mav'}`
                      : ''
                  }`}
                  rel="noreferrer"
                  sx={{
                    borderRadius: 100,
                    '&&': { color: '#fff' },
                    textTransform: 'initial',
                  }}
                  target="_blank"
                  variant="contained"
                >
                  Inscrire {selectedGeogroup.title} à Geovelo Entreprise
                </Button>
                <Typography>
                  Pour inscrire votre organisation{' '}
                  {config === 'mdv' ? 'au Mois du Vélo' : 'à Mai à Vélo'}, votre communauté Geovelo
                  va être inscrite à la plateforme Geovelo Entreprise. Geovelo Entreprise vous
                  permettra d'animer votre Challenge{' '}
                  {config === 'mdv' ? 'Mois du Vélo' : 'Mai à Vélo'} et d'accéder à d'autres
                  fonctionnalités pour favoriser le développement de la pratique parmi vos salariés.
                </Typography>
                <Typography>
                  Si vous ne parvenez pas à rejoindre l’administration de votre communauté, veuillez
                  nous contacter à <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
                </Typography>
              </>
            )
          ) : selectedGeogroup.type === 'city' ? (
            <>
              {selectedGeogroup.partner &&
              partners?.find(({ geoGroupId }) => geoGroupId === selectedGeogroup.id) ? (
                <>
                  <Button
                    disableElevation
                    color="primary"
                    component="a"
                    href="https://cycling-insights.io/"
                    rel="noreferrer"
                    sx={{
                      borderRadius: 100,
                      '&&': { color: '#fff' },
                      textTransform: 'initial',
                    }}
                    target="_blank"
                    variant="contained"
                  >
                    Consulter {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Cycling
                    Insights
                  </Button>
                  <Typography>
                    En tant que partenaire Geovelo, votre territoire est d'ores et déjà inscrit{' '}
                    {config === 'mdv' ? 'au Mois du Vélo' : 'à Mai à Vélo'} {year}. Retrouvez les
                    informations de votre challenge sur Cycling Insights !
                  </Typography>
                  <Typography>
                    Si vous ne parvenez pas à rejoindre l’administration de votre communauté,
                    veuillez nous contacter à{' '}
                    <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
                  </Typography>
                </>
              ) : (
                <>
                  <Button
                    disableElevation
                    color="primary"
                    component="a"
                    href={`${environment.frontendUrl}/fr/communities/${selectedGeogroup.id}/${
                      currentUser
                        ? `?auth-token=${HttpService.authorizationToken?.substring(
                            HttpService.authorizationToken.indexOf(' ') + 1,
                          )}&user-id=${currentUser.id}`
                        : ''
                    }`}
                    rel="noreferrer"
                    sx={{
                      borderRadius: 100,
                      '&&': { color: '#fff' },
                      textTransform: 'initial',
                    }}
                    target="_blank"
                    variant="contained"
                  >
                    Rejoindre {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Geovelo
                  </Button>
                  <Typography>
                    Il vous suffira ensuite de vous rendre sur la page de la communauté de votre
                    territoire (Mon profil &gt; Communauté &gt; Page de la communauté) et de cocher
                    que vous souhaitez participer
                    {config === 'mdv' ? ' au Mois du Vélo' : ' à Mai à Vélo'} {year} sur le bloc
                    suivant :
                  </Typography>
                  <img src={registerGeovelo} width="100%" />
                  <Typography>
                    Si vous ne parvenez pas à rejoindre l’administration de votre communauté,
                    veuillez nous contacter à{' '}
                    <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
                  </Typography>
                </>
              )}
            </>
          ) : (
            <>
              <Button
                disableElevation
                color="primary"
                component="a"
                href={`${environment.frontendUrl}/fr/communities/${selectedGeogroup.id}/${
                  currentUser
                    ? `?auth-token=${HttpService.authorizationToken?.substring(
                        HttpService.authorizationToken.indexOf(' ') + 1,
                      )}&user-id=${currentUser.id}`
                    : ''
                }`}
                rel="noreferrer"
                sx={{
                  borderRadius: 100,
                  '&&': { color: '#fff' },
                  textTransform: 'initial',
                }}
                target="_blank"
                variant="contained"
              >
                Rejoindre {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Geovelo
              </Button>
              <Typography>
                Il vous suffira ensuite de vous rendre sur la page de la communauté (Mon profil &gt;
                Communauté &gt; Page de la communauté privée) et de cocher que vous souhaitez
                participer
                {config === 'mdv' ? ' au Mois du Vélo' : ' à Mai à Vélo'} {year} sur le bloc suivant
                :
              </Typography>
              <img src={registerGeovelo} width="100%" />
              <Typography>
                Si vous ne parvenez pas à rejoindre l’administration de votre communauté, veuillez
                nous contacter à <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
              </Typography>
            </>
          )}
        </>
      ) : (
        <>
          {entityType === 'company' ? (
            <>
              <Button
                disableElevation
                color="primary"
                component="a"
                href={`${environment.enterpriseUrl}subscription/connect${
                  currentUser
                    ? `?auth-token=${HttpService.authorizationToken?.substring(
                        HttpService.authorizationToken.indexOf(' ') + 1,
                      )}&user-id=${currentUser.id}&utm_source=${config || 'mav'}`
                    : ''
                }`}
                rel="noreferrer"
                sx={{
                  borderRadius: 100,
                  '&&': { color: '#fff' },
                  textTransform: 'initial',
                }}
                target="_blank"
                variant="contained"
              >
                Rejoindre {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Geovelo
                Entreprise
              </Button>
              <Typography>
                Si vous ne parvenez pas à créer votre entreprise, veuillez nous contacter à{' '}
                <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
              </Typography>
            </>
          ) : (
            <>
              <Button
                disableElevation
                color="primary"
                component="a"
                href={`${environment.frontendUrl}/fr/communities/${
                  currentUser
                    ? `?auth-token=${HttpService.authorizationToken?.substring(
                        HttpService.authorizationToken.indexOf(' ') + 1,
                      )}&user-id=${currentUser.id}`
                    : ''
                }`}
                rel="noreferrer"
                sx={{
                  borderRadius: 100,
                  '&&': { color: '#fff' },
                  textTransform: 'initial',
                }}
                target="_blank"
                variant="contained"
              >
                Rejoindre {config === 'mdv' ? 'le Mois du Vélo' : 'Mai à Vélo'} sur Geovelo
              </Button>
              <Typography>
                La communauté du territoire existe déjà ! Vous devez d'abord la rejoindre (trouvable
                grâce au champs de recherche), puis il vous suffira ensuite de vous rendre sur la
                page de la communauté du territoire (Mon profil &gt; Communauté &gt; Page de la
                communauté) et de cocher que vous souhaitez participer
                {config === 'mdv' ? ' au Mois du Vélo' : ' à Mai à Vélo'} {year} sur le bloc suivant
                :
              </Typography>
              <img src={registerGeovelo} width="100%" />
              <Typography>
                Si vous ne parvenez pas à rejoindre l’administration de votre communauté, veuillez
                nous contacter à <a href="mailto:support@geovelo.fr">support@geovelo.fr</a>
              </Typography>
            </>
          )}
        </>
      )}
    </RegisterLayout>
  );
}

export default Redirect;
